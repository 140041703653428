import Button from "components/Button";
import CTABanner from "components/CTABanner";
import SEO from "components/SEO";
import { HeadFC, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "layout";
import React, { useState } from "react";
import { BasePageContext, TemplatePageProps } from "utils/types";

export default (props: TemplatePageProps<BasePageContext>) => {
  const { appData } = props.pageContext;

  const [popup, setPopup] = useState(false);

  return (
    <Layout appData={appData} bodyClass="contact">
      {popup && (
        <div>
          <div className="success-popup">
            <StaticImage src="../images/thank-you.png" alt="Thank you" />
            <div className="text">
              <p>
                We have received your inquiry with Hillary W Taylor Interiors.
                Kindly allow 48 hours for us to follow up to your note. We
                sincerely look forward to connecting.
              </p>
            </div>
            <div className="next-previous">
              <div className="prev-container">
                <Link className="prev" to={`/`}>
                  Back to website
                </Link>
              </div>
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      )}
      <div className="spaced-50">
        <h1 className="with-divider">CONTACT</h1>
        <p className="text-center">
          Want to say hello? Have a project you would like to collaborate on?
          <br /> Fill out the form below. I’d love to hear from you!
          <br />
          Or you can reach us at{" "}
          <a href="mailto:info@hillarytaylorinteriors.com">
            info@hillarytaylorinteriors.com
          </a>
        </p>
        <div className="container--medium">
          <form
            name="contact"
            method="post"
            action="https://devapi.hillarytaylorinteriors.com/contact-us/rest"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="email"
              name="your-email"
              placeholder="example@example.com"
            />
            <input type="text" name="name" placeholder="name" />
            <input type="email" name="email" placeholder="email address" />
            <input type="text" name="subject" placeholder="subject" />
            <textarea name="body" placeholder="message"></textarea>
            <div className="text-center">
              <button type="submit" className="button blue" onClick={(e) => {
                  e.preventDefault();
                  setPopup(true)
                }
                }>
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export const Head: HeadFC = () => (
  <SEO
    title="Contact Me"
    ogMeta={{
      description: "Fill out the form below. I’d love to hear from you!",
    }}
  ></SEO>
);
